<template>
<div>

    <b-row>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-edit fa-md"></i> <span class="h5"> Evaluación de aspectos</span>
                </CCardHeader>
                <CCardBody>
                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                        <b-form @submit.stop.prevent="handleSubmit(guardarEvaluacion)">
                            <b-row>
                                <b-col md="4">
                                    <validation-provider name="proyecto" rules="required" v-slot="{errors}">
                                        <b-form-group label="Proyecto:" class="mb-2">
                                            <v-select :reduce="comboProyecto =>comboProyecto.idProyecto" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosNuevaEvaluacion.idProyecto , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosNuevaEvaluacion.idProyecto" :options="comboProyecto" @search:blur="blurProyecto">
                                                <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                            </v-select>
                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="4">
                                    <validation-provider name="fecha de evaluación:" :rules="{required: true}" v-slot="validationContext">
                                        <b-form-group label="Fecha de evaluación:" class="mb-2">
                                            <b-form-input type="date" :state="getValidationState(validationContext)" v-model.lazy="datosNuevaEvaluacion.fecha"></b-form-input>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="4">
                                    <validation-provider name="código informe:" :rules="{required: true}" v-slot="validationContext">
                                        <b-form-group label="Código informe:" class="mb-2">
                                            <b-form-input type="text" placeholder="Ingrese el código" :state="getValidationState(validationContext)" v-model.lazy="datosNuevaEvaluacion.codigoInforme"></b-form-input>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="4">
                                    <validation-provider name="tipo de información" :rules="{  }" v-slot="validationContext">
                                        <b-form-group label="Tipo de información:" class="mb-2">
                                            <b-form-textarea :state="getValidationState(validationContext)" v-model.lazy="datosNuevaEvaluacion.tipoInformacion" placeholder="Ingrese el tipo de información" rows="4" max-rows="6"></b-form-textarea>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="4">
                                    <validation-provider name="documentación" :rules="{}" v-slot="validationContext">
                                        <b-form-group label="Documentación:" class="mb-2">
                                            <b-input-group>
                                                <b-input-group-prepend v-if="datosNuevaEvaluacion.urlDocumentacion != ''">
                                                    <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosNuevaEvaluacion.urlDocumentacion)" v-c-tooltip="'Descargar'">
                                                        <i class="fas fa-download fa-xs"></i>
                                                    </b-button>
                                                </b-input-group-prepend>
                                                <b-form-file ref="file" v-model.lazy="datosNuevaEvaluacion.documentacion" v-on:change="handleFileUpload" :state="getValidationState(validationContext)" :placeholder="datosNuevaEvaluacion.nombreDocumentacion ? datosNuevaEvaluacion.nombreDocumentacion : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="datosNuevaEvaluacion.nombreDocumentacion ? datosNuevaEvaluacion.nombreDocumentacion : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-input-group>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col v-if="datosNuevaEvaluacion.idEvaluacion!=''" md="4">
                                    <validation-provider name="motivo revisión" :rules="{  }" v-slot="validationContext">
                                        <b-form-group label="Motivo revisión:" class="mb-2">
                                            <b-form-textarea :state="getValidationState(validationContext)" v-model.lazy="datosNuevaEvaluacion.motivoRevision" placeholder="Ingrese el motivo revisión" rows="4" max-rows="6"></b-form-textarea>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                            </b-row>

                            <!-- <b-col class="mt-3" md="12">
                                <span class="h6 text-muted">POR FAVOR, AGREGUE LOS RIESGOS QUE DESEA EVALUAR</span>
                                <CButton size="sm" class="float-right" color="dark" @click="modalAgregarRiesgos = true">
                                    <i class="fas fa-plus fa-sm"></i> Agregar riesgos
                                </CButton>
                            </b-col> -->
                            <b-col class="mt-3">
                                <hr>
                            </b-col>
                            <b-col md="12" class="mt-3">
                                <template>
                                    <div class="accordion" role="tablist" v-for="(aspectos, k) in datosAcordionAspectos" :key="k">
                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 " :class="aspectos.prioridad=='NORMAL' ? 'bg-accordion-success' : aspectos.prioridad=='ANORMAL' ? 'bg-accordion-warning' : aspectos.prioridad=='EMERGENCIA' ? 'bg-accordion-danger' : 'bg-accordion'" role="tab">
                                                <div block v-b-toggle="'accordion-'+aspectos.idAspecto" :class="aspectos.prioridad=='NORMAL' ? 'bg-accordion-success' : aspectos.prioridad=='ANORMAL' ? 'bg-accordion-warning' : aspectos.prioridad=='EMERGENCIA' ? 'bg-accordion-danger' : 'bg-accordion'">
                                                    <span class=" h6"> {{aspectos.nombre}}</span>
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse :id="'accordion-'+aspectos.idAspecto" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-col md="6">
                                                            <b-row>
                                                                <b-col md="12">
                                                                    <validation-provider :name="'impactos de '+aspectos.nombre" :rules="{ required: true }" v-slot="validationContext">
                                                                        <b-form-group label="Impactos:" class="mb-2">
                                                                            <b-form-textarea :state="getValidationState(validationContext)" v-model.lazy="aspectos.impactos" placeholder="Ingrese los impactos" rows="4" max-rows="6"></b-form-textarea>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                                <b-col md="12">
                                                                    <b-form-group label="Prioridad:" class="mt-2">
                                                                        <b-form-input type="text" class="disabled-white" disabled v-model.lazy="aspectos.prioridad"></b-form-input>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>
                                                        <b-col md="6">
                                                            <b-row>
                                                                <b-col md="12">
                                                                    <validation-provider :name="'frecuencia de '+aspectos.nombre" :rules="{required: true}" v-slot="validationContext">
                                                                        <b-form-group label="Frecuencia:" class="mb-2">
                                                                            <b-form-select v-on:change="generarPrioridad(k)" :state="getValidationState(validationContext)" :options="comboFrecuencia" value-field="idFrecuencia" text-field="descripcion" v-model.lazy="aspectos.frecuencia">
                                                                                <template v-slot:first>
                                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                                </template>
                                                                            </b-form-select>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                                <b-col md="12">
                                                                    <validation-provider :name="'importancia de '+aspectos.nombre" :rules="{required: true}" v-slot="validationContext">
                                                                        <b-form-group label="Importancia:" class="mb-2">
                                                                            <b-form-select v-on:change="generarPrioridad(k)" :state="getValidationState(validationContext)" :options="comboImportancia" value-field="idImportancia" text-field="descripcion" v-model.lazy="aspectos.importancia">
                                                                                <template v-slot:first>
                                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                                </template>
                                                                            </b-form-select>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                                <b-col md="12">
                                                                    <validation-provider :name="'capacidad de '+aspectos.nombre" :rules="{required: true}" v-slot="validationContext">
                                                                        <b-form-group label="Capacidad:" class="mb-2">
                                                                            <b-form-select v-on:change="generarPrioridad(k)" :state="getValidationState(validationContext)" :options="comboCapacidad" value-field="idCapacidad" text-field="descripcion" v-model.lazy="aspectos.capacidad">
                                                                                <template v-slot:first>
                                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                                </template>
                                                                            </b-form-select>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>
                                                        <b-col md="12" class="mt-2">
                                                            <b-table :items="datosAcordionAspectos[k].listaGestiones.filter(x => x.estado == '2')" :fields="cabeceraGestiones" class="table-custom" mediun responsive outlined fixed>
                                                                <template v-slot:cell(gestiones)="row">
                                                                    <b-row>
                                                                        <b-col md="3">
                                                                            <b-form-group label="Fecha de gestión:" class="mb-2">
                                                                                <b-form-input v-model.lazy="row.item.fechaGestion" type="date"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-col>
                                                                        <b-col md="7">
                                                                            <validation-provider name="documentación" :rules="{}" v-slot="validationContext">
                                                                                <b-form-group label="Documentación:" class="mb-2">
                                                                                    <b-input-group>
                                                                                        <b-input-group-prepend v-if="row.item.urlDocumentacion != ''">
                                                                                            <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(row.item.urlDocumentacion)" v-c-tooltip="'Descargar'">
                                                                                                <i class="fas fa-download fa-xs"></i>
                                                                                            </b-button>
                                                                                        </b-input-group-prepend>
                                                                                        <b-form-file :state="getValidationState(validationContext)" :placeholder="row.item.nombreDocumentacion ? row.item.nombreDocumentacion : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="row.item.nombreDocumentacion ? row.item.nombreDocumentacion : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir" v-model.lazy="row.item.documentacion"></b-form-file>
                                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                    </b-input-group>
                                                                                </b-form-group>
                                                                            </validation-provider>
                                                                        </b-col>
                                                                        <b-col md="2" class="text-right mt-3">
                                                                            <b-button @click="quitarGestion(row, k)" v-if="datosAcordionAspectos[k].listaGestiones.filter(x => x.estado == '2').length >= 2" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                               <i class="fas fa-trash-alt  my-0 mx-0"></i> Eliminar gestión
                                                                            </b-button>
                                                                            <!-- <b-button @click="eliminarGestion(row, k)" v-if="datosAcordionAspectos[k].listaGestiones.length >= 2 && row.item.idEvaluacionDetalleGestion!=''" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                               <i class="fas fa-trash-alt  my-0 mx-0"></i> Eliminar gestión
                                                                            </b-button> -->
                                                                        </b-col>
                                                                        <b-col md="12" class="mt-2">
                                                                            <span class="h6 text-muted">Medidas a implantar</span>
                                                                            <CButton size="sm" class="float-right" color="dark" @click="agregarMedidaImplantar(row, k)">
                                                                                <i class="fas fa-plus fa-sm"></i> Agregar medida
                                                                            </CButton>
                                                                        </b-col>
                                                                        <b-col md="12" class="mt-2">
                                                                            <b-table :items="row.item.listaMedidas.filter(x => x.estado == '2')" :fields="cabeceraMedidas" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">
                                                                                <template #table-colgroup="cabeceraMedidas">
                                                                                    <col v-for="field in cabeceraMedidas.fields" :key="field.key" :style="{ width: field.key === 'responsable' ? '30%' : 'recomendacion' ? '40%' : '10%' }">
                                                                                </template>
                                                                                <template v-slot:cell(responsable)="rowMedidas">
                                                                                    <v-select :reduce="comboCargoResponsable =>comboCargoResponsable.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" v-model.lazy="rowMedidas.item.idPuestoTrabajo" :options="comboCargoResponsable">
                                                                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Puestos de trabajo'}"> Registra aquí</router-link></span>
                                                                                    </v-select>
                                                                                    <v-select :reduce="comboResponsable =>comboResponsable.idEmpleado" class="mt-2" label="nombres" placeholder="Seleccione una opción" v-model.lazy="rowMedidas.item.idEmpleado" :options="comboResponsable">
                                                                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Recursos humanos'}"> Registra aquí</router-link></span>
                                                                                    </v-select>
                                                                                </template>
                                                                                <template v-slot:cell(recomendacion)="rowMedidas">
                                                                                    <b-form-textarea placeholder="Ingrese la recomendación" v-model.lazy="rowMedidas.item.recomendacion" rows="3" max-rows="6"></b-form-textarea>
                                                                                </template>
                                                                                <template v-slot:cell(plazo)="rowMedidas">
                                                                                    <b-form-select v-model.lazy="rowMedidas.item.plazo" :options="comboPlazo" value-field="idPlazo" text-field="descripcion">
                                                                                        <template v-slot:first>
                                                                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                                        </template>
                                                                                    </b-form-select>
                                                                                </template>
                                                                                <template v-slot:cell(ejecutada)="rowMedidas">
                                                                                    <b-form-checkbox plain :id="'checkbox-'+k+'-'+row.index+'-'+rowMedidas.index" v-model.lazy="rowMedidas.item.ejecutada" :name="'checkbox-'+k+'-'+row.index+'-'+rowMedidas.index" value="2" unchecked-value="1"></b-form-checkbox>
                                                                                </template>
                                                                                <template #cell(acciones)="param">
                                                                                    <b-button @click="quitarMedidaImplantar(param, row, k)" v-if="datosAcordionAspectos[k].listaGestiones[row.index].listaMedidas.filter(x => x.estado=='2').length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                                    </b-button>
                                                                                    <!-- <b-button @click="eliminarMedida(param, row, k)" v-if="datosAcordionAspectos[k].listaGestiones[row.index].listaMedidas.length >= 2 && param.item.idEvaluacionDetalleMedida!=''" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                                    </b-button> -->
                                                                                </template>
                                                                            </b-table>
                                                                        </b-col>
                                                                    </b-row>
                                                                </template>
                                                            </b-table>
                                                        </b-col>
                                                        <b-col md="12" class="text-center mt-2">
                                                            <CButton size="sm" color="dark" @click="agregarGestion(k)">
                                                                <i class="fas fa-plus fa-sm"></i> Agregar gestión
                                                            </CButton>
                                                        </b-col>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </template>
                            </b-col>
                            <b-col class="my-2 text-center" md="12">
                                <!-- <b-button class="mr-2" :to="{name: 'Selección riesgos'}" variant="dark">
                                    <i class="fas fa-arrow-left"></i> Volver
                                </b-button> -->
                                <b-button v-if="$route.params.id" :to="{name: 'Selección aspectos', params: {id: $route.params.id}}" variant="dark" class=" mr-2">
                                    <i class="fas fa-arrow-left"></i> Volver
                                </b-button>
                                <b-button v-if="!$route.params.id" :to="{name: 'Selección aspectos'}" variant="dark" class=" mr-2">
                                    <i class="fas fa-arrow-left"></i> Volver
                                </b-button>
                                <b-button :disabled="disabled" v-if=" datosAcordionAspectos.length>0" type="submit" variant="success" class=" mr-2">
                                    <i class="fas fa-arrow-right"></i> Continuar
                                </b-button>
                                <!-- <b-button :disabled="disabled"   v-if="checkPermissions('004-14001-EVA','u')==1 && datosAcordionAspectos.length>0 && $route.params.id && $route.params.date" type="submit" variant="success" class=" mr-2">
                                    <i class="fas fa-arrow-right"></i> Continuar
                                </b-button>
                                 -->
                            </b-col>
                        </b-form>
                    </validation-observer>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            modalAgregarRiesgos: false,
            BtnMostrar: false,
            cabecera: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "riesgo",
                    label: "Riesgo",
                    class: "text-center",
                },
                {
                    key: "selected",
                    label: "Selección",
                    class: "text-center",
                }
            ],
            cabeceraGestiones: [{
                key: 'gestiones',
                label: 'Gestiones',
            }],
            cabeceraMedidas: [{
                key: 'responsable',
                label: 'Responsable',
                class: 'text-center'
            }, {
                key: 'recomendacion',
                label: 'Recomendación',
                class: 'text-center'
            }, {
                key: 'plazo',
                label: 'Plazo',
                class: 'text-center'
            }, {
                key: 'ejecutada',
                label: 'Ejecutada',
                class: 'text-center'
            }, {
                key: 'acciones',
                label: '',
                class: 'text-center'
            }],
            listaRiesgos: [],

            arrayAspectos: [],
            datosAcordionAspectos: [],
            datosNuevaEvaluacion: {
                idEvaluacion: '',
                idProyecto: null,
                fecha: moment().format('YYYY-MM-DD'),
                codigoInforme: '',
                tipoInformacion: '',
                motivoRevision: '',
                documentacion: null,
                urlDocumentacion: '',
                nombreDocumentacion: ''
            },
            comboFrecuencia: [{
                idFrecuencia: 1,
                descripcion: "Menos 1 / mes",
            }, {
                idFrecuencia: 2,
                descripcion: "más 1 / mes",
            }, {
                idFrecuencia: 3,
                descripcion: "Constantemente",
            }],

            comboImportancia: [{
                idImportancia: 1,
                descripcion: "No peligroso",
            }, {
                idImportancia: 2,
                descripcion: "Peligroso",
            }],

            comboCapacidad: [{
                idCapacidad: 1,
                descripcion: "Acciones realizadas",
            }, {
                idCapacidad: 2,
                descripcion: "Pueden realizarse acciones",
            }, {
                idCapacidad: 3,
                descripcion: "Pueden realizarse acciones garantizadas",
            }],
            comboPlazo: [{
                idPlazo: '1',
                descripcion: '6 Meses'
            }, {
                idPlazo: '2',
                descripcion: '12 Meses'
            }, {
                idPlazo: '3',
                descripcion: '18 Meses'
            }, {
                idPlazo: '4',
                descripcion: 'Otros'
            }],
            comboCargoResponsable: [],
            comboResponsable: [],
            comboProyecto: [],
            datosSession: {
                idCliente: ''
            },
            disabled: false,
            datosEvaluacionDetalleGestion: [],
            datosEvaluacionDetalleMedida: [],

        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        descargarDocumento(url) {
            window.open(url)
        },
        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }

                this.datosNuevaEvaluacion.documentacion = this.$refs.file.files[0];
            }
        },
        blurPuestoTrabajo() {
            this.computedForm.refs.cargo.validate();
        },
        blurProyecto() {
            this.computedForm.refs.proyecto.validate();
        },
        listarProyectos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-proyectos", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboProyecto = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        agregarRiesgos() {
            let me = this;
            let arrayAspectos = me.$route.params.arrayAspectos;
            for (const i in arrayAspectos) {
                me.datosAcordionAspectos.push({
                    idEvaluacionDetalleAspecto: '',
                    idEvaluacion: '',
                    idAspecto: arrayAspectos[i].idAspecto,
                    nombre: arrayAspectos[i].nombre,
                    impactos: arrayAspectos[i].impactos,
                    frecuencia: null,
                    importancia: null,
                    capacidad: null,
                    prioridad: '',
                    listaGestiones: [{
                        idEvaluacionDetalleGestion: '',
                        idEvaluacionDetalleAspecto: '',
                        fechaGestion: moment().format('YYYY-MM-DD'),
                        listaMedidas: [{
                            idEvaluacionDetalleMedida: '',
                            idEvaluacionDetalleGestion: '',
                            idPuestoTrabajo: '',
                            idEmpleado: '',
                            recomendacion: '',
                            plazo: null,
                            ejecutada: '1',
                            estado: 2,
                        }],
                        documentacion: null,
                        urlDocumentacion: '',
                        nombreDocumentacion: '',
                        estado: 2,
                    }],
                });
            }
        },
        consultarEvaluacionRiesgo() {
            let me = this;
            let arrayAspectos = me.$route.params.arrayAspectos;
            me.datosAcordionAspectos = arrayAspectos;

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/consultar-evaluacion-aspecto", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            idEvaluacion: me.$route.params.id,
                        },
                    }
                )
                .then(function (response) {
                    me.datosNuevaEvaluacion.idEvaluacion = response.data[0].idEvaluacion;
                    me.datosNuevaEvaluacion.idProyecto = response.data[0].idProyecto;
                    me.datosNuevaEvaluacion.fecha = response.data[0].fecha;
                    me.datosNuevaEvaluacion.codigoInforme = response.data[0].codigoInforme;
                    me.datosNuevaEvaluacion.tipoInformacion = response.data[0].tipoInformacion;
                    me.datosNuevaEvaluacion.motivoRevision = response.data[0].motivoRevision;
                    me.datosNuevaEvaluacion.urlDocumentacion = response.data[0].urlDocumentacion;
                    me.datosNuevaEvaluacion.nombreDocumentacion = response.data[0].nombreDocumentacion;

                    for (const j in arrayAspectos) {
                        for (const i in response.data) {
                            if (arrayAspectos[j].idAspecto == response.data[i].idAspecto) {
                                me.datosAcordionAspectos.splice(j, 1, {
                                    idEvaluacionDetalleAspecto: response.data[i].idEvaluacionDetalleAspecto,
                                    idEvaluacion: response.data[i].idEvaluacion,
                                    idAspecto: response.data[i].idAspecto,
                                    nombre: response.data[i].nombre,
                                    impactos: response.data[i].impactos,
                                    frecuencia: response.data[i].frecuencia,
                                    importancia: response.data[i].importancia,
                                    capacidad: response.data[i].capacidad,
                                    prioridad: response.data[i].prioridad == 1 ? 'NORMAL' : response.data[i].prioridad == 2 ? 'ANORMAL' : response.data[i].prioridad == 3 ? 'EMERGENCIA' : '',
                                    listaGestiones: [],
                                })
                            }
                        }
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        consultarEvaluacionDetalleGestion() {
            let me = this;

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/consultar-evaluacion-detalle-gestion-sgma", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            idEvaluacion: me.$route.params.id,
                        },
                    }
                )
                .then(function (response) {
                    //me.datosEvaluacionDetalleGestion = response.data;
                    for (const i in response.data) {
                        me.datosEvaluacionDetalleGestion.push({
                            idEvaluacionDetalleGestion: response.data[i].idEvaluacionDetalleGestion,
                            idEvaluacionDetalleAspecto: response.data[i].idEvaluacionDetalleAspecto,
                            fechaGestion: response.data[i].fechaGestion,
                            listaMedidas: [],
                            documentacion: null,
                            urlDocumentacion: response.data[i].urlDocumentacion,
                            nombreDocumentacion: response.data[i].nombreDocumentacion,
                            estado: response.data[i].estado,
                        })
                    }
                    me.consultarEvaluacionDetalleMedida();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        consultarEvaluacionDetalleMedida() {
            let me = this;

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/consultar-evaluacion-detalle-medida-sgma", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            idEvaluacion: me.$route.params.id,
                        },
                    }
                )
                .then(function (response) {
                    me.datosEvaluacionDetalleMedida = response.data;

                    for (const j in me.datosEvaluacionDetalleGestion) {
                        for (const k in me.datosEvaluacionDetalleMedida) {
                            if (me.datosEvaluacionDetalleGestion[j].idEvaluacionDetalleGestion == me.datosEvaluacionDetalleMedida[k].idEvaluacionDetalleGestion) {
                                me.datosEvaluacionDetalleGestion[j].listaMedidas.push({
                                    idEvaluacionDetalleMedida: me.datosEvaluacionDetalleMedida[k].idEvaluacionDetalleMedida,
                                    idEvaluacionDetalleGestion: me.datosEvaluacionDetalleMedida[k].idEvaluacionDetalleGestion,
                                    idPuestoTrabajo: me.datosEvaluacionDetalleMedida[k].idCargoResponsable,
                                    idEmpleado: me.datosEvaluacionDetalleMedida[k].idResponsable,
                                    recomendacion: me.datosEvaluacionDetalleMedida[k].recomendacion,
                                    plazo: me.datosEvaluacionDetalleMedida[k].plazo,
                                    ejecutada: me.datosEvaluacionDetalleMedida[k].ejecutada,
                                    estado: me.datosEvaluacionDetalleMedida[k].estado,
                                })
                            }
                        }

                    }
                    for (const i in me.datosAcordionAspectos) {
                        for (const j in me.datosEvaluacionDetalleGestion) {
                            if (me.datosAcordionAspectos[i].idEvaluacionDetalleAspecto == me.datosEvaluacionDetalleGestion[j].idEvaluacionDetalleAspecto) {
                                me.datosAcordionAspectos[i].listaGestiones.push(me.datosEvaluacionDetalleGestion[j]);
                            }
                        }
                    }

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarPuestosTrabajo() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-puestos-trabajo", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboCargoResponsable = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarEmpleados() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-empleados", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboResponsable = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        guardarEvaluacion() {
            let me = this;
            if (me.$route.params.id) {
                me.$router.push({
                    name: 'Generar gráfico evaluación de aspectos',
                    params: {
                        id: me.$route.params.id,
                        datosAcordionAspectos: me.datosAcordionAspectos,
                        datosNuevaEvaluacion: me.datosNuevaEvaluacion,
                    }
                });
            } else {
                me.$router.push({
                    name: 'Generar gráfico evaluación de aspectos',
                    params: {
                        datosAcordionAspectos: me.datosAcordionAspectos,
                        datosNuevaEvaluacion: me.datosNuevaEvaluacion,
                    }
                });
            }

            // me.disabled = true;
            // const formData = new FormData();
            // /*---Necesario para ruta de archivo --*/
            // formData.append("file", me.datosNuevaEvaluacion.documentacion);
            // formData.append("folder", 'sgma/evaluacion-aspectos');

            // for (const i in me.datosAcordionAspectos) {
            //     for (const j in me.datosAcordionAspectos[i].listaGestiones) {
            //         if (me.datosAcordionAspectos[i].listaGestiones[j].documentacion != null) {
            //             formData.append("fileaa" + i + "aa" + j, me.datosAcordionAspectos[i].listaGestiones[j].documentacion);
            //         }

            //     }
            // }
            // formData.append("folder2", 'sgma/evaluacion-aspectos/gestiones-documentos');

            // formData.append("datosNuevaEvaluacion", JSON.stringify(me.datosNuevaEvaluacion));
            // formData.append("datosEvaluacionDetalle", JSON.stringify(me.datosAcordionAspectos));
            // formData.append("idCliente", me.datosSession.idCliente);

            // axios
            //     .post(
            //         CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-evaluacion-aspecto",
            //         formData, {
            //             headers: {
            //                 Authorization: `Bearer ${localStorage.token}`
            //             },
            //         }
            //     )
            //     .then(function (response) {
            //         let color = response.data.resultado == 1 ? "success" : "error";
            //         me.swat(color, response.data.mensaje);
            //         me.$router.push({
            //             name: 'Evaluación 14001',
            //         });
            //         me.disabled = false;
            //     })
            //     .catch(function (error) {
            //         me.swat('error', 'Algo salió mal!')
            //         me.disabled = false;
            //     });
        },
        agregarMedidaImplantar(row, k) {
            let me = this;
            me.datosAcordionAspectos[k].listaGestiones[row.index].listaMedidas.push({
                idEvaluacionDetalleMedida: '',
                idEvaluacionDetalleGestion: '',
                idPuestoTrabajo: '',
                idEmpleado: '',
                recomendacion: '',
                plazo: null,
                ejecutada: '1',
                estado: 2,
            })
        },
        quitarMedidaImplantar(param, row, k) {
            let me = this;
            if (this.$route.params.id) {
                let listaMedidas = me.datosAcordionAspectos[k].listaGestiones[row.index].listaMedidas.filter(x => x.estado == 2);
                if (listaMedidas[param.index].idEvaluacionDetalleMedida) {
                    listaMedidas[param.index].estado = 1;
                } else if (!listaMedidas[param.index].idEvaluacionDetalleMedida) {
                    listaMedidas[param.index].estado = 0;
                    for (let e in me.datosAcordionAspectos[k].listaGestiones[row.index].listaMedidas) {
                        if (me.datosAcordionAspectos[k].listaGestiones[row.index].listaMedidas[e].estado == 0) {
                            me.datosAcordionAspectos[k].listaGestiones[row.index].listaMedidas.splice(e, 1);
                        }
                    }
                }
            } else {
                me.datosAcordionAspectos[k].listaGestiones[row.index].listaMedidas.splice(param.index, 1);
            }
        },
        agregarGestion(k) {
            let me = this;
            me.datosAcordionAspectos[k].listaGestiones.push({
                idEvaluacionDetalleGestion: '',
                idEvaluacionDetalleAspecto: '',
                fechaGestion: moment().format('YYYY-MM-DD'),
                listaMedidas: [{
                    idEvaluacionDetalleMedida: '',
                    idEvaluacionDetalleGestion: '',
                    idPuestoTrabajo: '',
                    idEmpleado: '',
                    recomendacion: '',
                    plazo: null,
                    ejecutada: '1',
                    estado: 2,
                }],
                documentacion: null,
                urlDocumentacion: '',
                nombreDocumentacion: '',
                estado: 2,
            })
        },
        quitarGestion(row, k) {
            let me = this;
            if (this.$route.params.id) {
                let listaGestiones = me.datosAcordionAspectos[k].listaGestiones.filter(x => x.estado == 2);
                if (listaGestiones[row.index].idEvaluacionDetalleGestion) {
                    listaGestiones[row.index].estado = 1;
                } else if (!listaGestiones[row.index].idEvaluacionDetalleGestion) {
                    listaGestiones[row.index].estado = 0;
                    for (let e in me.datosAcordionAspectos[k].listaGestiones) {
                        if (me.datosAcordionAspectos[k].listaGestiones[e].estado == 0) {
                            me.datosAcordionAspectos[k].listaGestiones.splice(e, 1);
                        }
                    }
                }
            } else {
                me.datosAcordionAspectos[k].listaGestiones.splice(row.index, 1);
            }
        },
        eliminarGestion(param, k){
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la gestión?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-evaluacion-detalle-gestion-sgma", {
                            idEvaluacionDetalleGestion: param.item.idEvaluacionDetalleGestion,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.datosAcordionAspectos[k].listaGestiones.splice(param.index, 1);
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        // eliminarMedida(param, row, k){
        //     let me = this
        //     me.$swal.fire({
        //         title: '¿Estas seguro de eliminar la medida?',
        //         text: "¡No podrás revertir esto!",
        //         icon: 'warning',
        //         showCancelButton: false,
        //         confirmButtonColor: '#d33',
        //         confirmButtonText: 'Eliminar',
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             axios
        //                 .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-evaluacion-detalle-medida-sgma", {
        //                     idEvaluacionDetalleMedida: param.item.idEvaluacionDetalleMedida,
        //                 }, {
        //                     headers: {
        //                         Authorization: `Bearer ${localStorage.token}`,
        //                     }
        //                 })
        //                 .then(function (response) {
        //                     let color = response.data.resultado == 1 ? "success" : "error";
        //                     me.swat(color, response.data.mensaje)
        //                     me.datosAcordionAspectos[k].listaGestiones[row.index].listaMedidas.splice(param.index, 1);
        //                 })
        //                 .catch(function (error) {
        //                     me.swat('error', 'Algo salió mal!')
        //                 });
        //         }
        //     })
        // },
        generarPrioridad(k) {
            let me = this;
            let frec = parseInt(me.datosAcordionAspectos[k].frecuencia);
            let imp = parseInt(me.datosAcordionAspectos[k].importancia);
            let cap = parseInt(me.datosAcordionAspectos[k].capacidad);
            let total = frec + imp + cap;

            if (total == 3 || total == 4) {
                me.datosAcordionAspectos[k].prioridad = 'NORMAL';
            } else if (total == 5 || total == 6) {
                me.datosAcordionAspectos[k].prioridad = 'ANORMAL';
            } else if (total >= 7) {
                me.datosAcordionAspectos[k].prioridad = 'EMERGENCIA';
            }
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        }
    },
    watch: {

    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarPuestosTrabajo();
            this.listarEmpleados();
            this.listarProyectos();

            if (this.$route.params.arrayAspectos != null && this.$route.params.id == null ) {
                this.agregarRiesgos();
            } else if (this.$route.params.arrayAspectos != null && this.$route.params.id != null) {
                this.consultarEvaluacionDetalleGestion();
                this.consultarEvaluacionRiesgo();
                //this.consultarEvaluacionDetalleMedida();
            }
        }
    }

}
</script>
